import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import LeftBox from "./LeftBox";
import Logout from "./Logout";
import { ReactComponent as ScaleWithLogo } from "../../assets/images/svg/scalewith-logo.svg";
import blueBgG from "../../assets/images/svg/bg.svg";
import mailIcon from "../../assets/images/svg/Mail.svg";

const Confirmation = () => {
  const is1300 = useMediaQuery("(max-width:1300px)");
  const { loginWithRedirect, logout } = useAuth0();

  const redirectLogin = () => {
    loginWithRedirect();
  };

  return (
    <Grid container columns={{ md: 12 }} className="resendMail">
      <Grid
        xs={4}
        sx={{
          backgroundImage: `url(${blueBgG})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignContent: "center",
          color: "#FFFFFF",
        }}
      >
        <LeftBox />
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={8}
        sx={{
          position: "relative",
          backgroundColor: "#ffffff",
        }}
      >
        <ScaleWithLogo
          width="320px"
          height="86px"
          style={{ marginBottom: "4em" }}
        />
        <Grid xs={12} sx={{ textAlign: "right" }}>
          <Logout />
        </Grid>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "15px",
            display: "flex",
            border: "1px solid #EAEAEA",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
            marginY: "20px",
            padding: `${is1300 ? "15px" : ""}`,
          }}
        >
          <Box sx={{ width: "600px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "2em",
              }}
            >
              <img src={mailIcon} alt="Mail" />
            </div>
            <Typography
              sx={{
                font: "inter",
                color: "#4F4F4F",
                textAlign: "center",
              }}
            >
              <h2 style={{ fontSize: "24px", fontWeight: "700" }}>
                Great, You email has been confirmed.
              </h2>
              <p>Please click on login with Auth0 to proceed with login.</p>
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "3em 0 2em 0",
              }}
            >
              <Button
                // sx={{ width: `${is1300 ? "600px" : "600px"}` }}
                variant="contained"
                size="large"
                color="info"
                onClick={() => {
                  document.cookie.split(";").forEach(function (c) {
                    document.cookie =
                      c.trim().split("=")[0] +
                      "=;" +
                      `expires=${new Date(0).toUTCString()}`;
                  });
                  redirectLogin();
                }}
              >
                Login With Auth0
              </Button>
            </div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Confirmation;
