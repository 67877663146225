import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { getUserInfo } from "../actions/getUserInfo";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { handleErrorActions } from "../../shared/errorActions";

const setCookie = (name: string, value: string) => {
  const cookieName =
    process.env.REACT_APP_ENV_TYPE !== "prod"
      ? `${process.env.REACT_APP_ENV_TYPE}_${name}`
      : name;
  const cookieDomain = process.env.REACT_APP_LOCALHOST
    ? `${process.env.REACT_APP_ENV_TYPE}.localhost`
    : `.${process.env.REACT_APP_BASE_DOMAIN}`;
  const secureFlag = "Secure";
  const cookieString = `${cookieName}=${value}; domain=${cookieDomain}; path=/; SameSite=None; ${secureFlag}`;
  document.cookie = cookieString;
};

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userData, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const currentOrigin = window.location.origin;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const loginData = { username, password };
    const loginUrl = `${process.env.REACT_APP_PAYLOAD_URL}`;
    const userInfoUrl = `${process.env.REACT_APP_API_URL}${
      process.env.REACT_APP_LOCALHOST ? "" : "/portal/v1"
    }/users/register`;

    try {
      const accessToken = await performLogin(loginUrl, loginData);
      const userInfo = await getUserInfo(accessToken);
      const registerResponse = await registerUser(
        userInfoUrl,
        userInfo,
        accessToken
      );

      await handleLoginSuccess(registerResponse, accessToken, userInfo);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const performLogin = async (url: string, loginData: object) => {
    const response = await axios.post(url, loginData, {
      headers: { "Content-Type": "application/json" },
    });

    if (response.status !== 200) {
      throw new Error(response.data.message || "Error logging in");
    }

    return response.data.accessToken;
  };

  const registerUser = async (
    url: string,
    userInfo: any,
    accessToken: string
  ) => {
    const response = await axios.get(url, {
      params: { username: userInfo.data.email, sub: userInfo.data.sub },
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (![200, 201].includes(response.status)) {
      throw new Error(response.data.message || "Registration failed");
    }

    return response;
  };

  const handleLoginSuccess = async (
    registerResponse: any,
    accessToken: string,
    userInfo: any
  ) => {
    if (!registerResponse.data.tenantAccessGrants.length) {
      throw new Error("No access rights");
    }

    if (
      registerResponse?.data?.tenantAccessGrants &&
      registerResponse?.data?.tenantAccessGrants.length > 0
    ) {
      setCookie("gw_auth_token", accessToken);
      setCookie("user_claims_details", JSON.stringify(registerResponse.data));
      toast.success("Successful");

      if (process.env.REACT_APP_LOCALHOST) {
        window.location.href = "http://dev.localhost:3002";
        return;
      }

      if (registerResponse?.data?.domain === "") {
        setIsLoading(false);
        const selectedAppType = (registerResponse?.data?.app).toLowerCase();

        const app =
          process.env.REACT_APP_ENV_TYPE !== "prod"
            ? `${process.env.REACT_APP_ENV_TYPE}.${selectedAppType}.v1`
            : `${selectedAppType}`;

        window.location.href = `https://${app}.scalewith.com`;
      } else {
        setIsLoading(false);
        window.location.href = `https://${registerResponse?.data?.domain}.scalewith.com`;
      }
    } else {
      if (
        registerResponse?.data?.userType === "sio_user" &&
        Cookies.get("customParam") !== undefined
      ) {
        localStorage.clear();
        setIsLoading(false);
        setCookie("gw_auth_token", accessToken);
        setCookie(
          "user_claims_details",
          JSON.stringify(registerResponse?.data)
        );
        // if we are localhost:
        if (process.env.REACT_APP_LOCALHOST) {
          window.location.href = process.env
            .REACT_APP_SCALEWITH_UI_FULL_LOCALHOST_DOMAIN as string;
        }
        window.location.href = `${Cookies.get("customParam")}`;
      } else {
        // logout();
      }
    }

    if (
      registerResponse.status === 404 ||
      registerResponse.status === 500 ||
      registerResponse.status === 401
    ) {
      setIsLoading(false);
      setCookie("gw_auth_token", accessToken);
      setCookie("user_email", btoa(userInfo?.email));
      window.location.href = `${currentOrigin}/account`;
    } else if (registerResponse.status >= 400) {
      toast.error(registerResponse.data.message);
      // logout();
      handleErrorActions(process.env.REACT_APP_AUTH0_DOMAIN as string);
    }
  };

  const handleError = (error: any) => {
    if (error.response) {
      console.error("API Error:", error.response.data.message);
      toast.error(error.response.data.message);
    } else {
      console.error("Error:", error.message);
      toast.error("An unexpected error occurred");
    }
  };

  const isFormValid = () => {
    return username !== "" && password !== "";
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUsername(value);

    if (!value.includes("@") || !value.endsWith(".com")) {
      setUsernameError("Username must be a valid email ending with @xxx.com");
    } else {
      setUsernameError("");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-40.6cac195f3f.png"
          alt="Logo"
          style={{ marginBottom: "16px" }}
        />
        <Typography component="h1" variant="h5">
          Sign in to your account
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={handleUsernameChange}
            error={!!usernameError}
            helperText={usernameError}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isFormValid()}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Sign in"
            )}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
