export const getAuthTokenKey = () => {
    if (window.location.origin.includes('dev')) {
        return {
        authTokenKey: 'dev_gw_auth_token',
        userClaimsKey: 'dev_user_claims_details',
        userEmailKey: 'dev_user_email',
        };
    }
    if (window.location.origin.includes('qa')) {
        return {
        authTokenKey: 'qa_gw_auth_token',
        userClaimsKey: 'qa_user_claims_details',
        userEmailKey: 'qa_user_email',
        };
    }
    return {
        authTokenKey: 'gw_auth_token',
        userClaimsKey: 'user_claims_details',
        userEmailKey: 'user_email',

    };
};
  
const authTokenKeys = getAuthTokenKey();
export const CK_AUTH_TOKEN = authTokenKeys.authTokenKey;
export const CK_REFRESH_TOKEN = 'gw_refresh_token';
export const CK_GW_TYPE = "gw_type";
export const CK_USER_CLAIMS = authTokenKeys.userClaimsKey;
export const CK_USER_EMAIL = authTokenKeys.userEmailKey;
export const CK_MPA_FORM = "mpaForm";
export const CK_CUSTOM_PARAM = "customParam";

