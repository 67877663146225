import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0 } from "@auth0/auth0-react";
import Account from "./tenant/pages/Account";
import Confirmation from "./tenant/pages/Confirmation";
import ResendEmail from "./tenant/pages/ResendEmail";
import RegisterAuth from "./tenant/pages/Register";
import SignUp from "./tenant/pages/SignUp";
import Logout from "./tenant/pages/Logout";
import Login from "./tenant/pages/Login";
import { Routes as AppRoutes } from './routePath';

// import Cookies from "js-cookie";

function App() {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  const currentPathname = window.location.pathname;
  const params = new URLSearchParams(window.location.search);
  const setCookie = (name: string, value: string) => {
    const cookieDomain = process.env.REACT_APP_LOCALHOST
      ? `${process.env.REACT_APP_ENV_TYPE}.localhost`
      : `.${process.env.REACT_APP_BASE_DOMAIN}`;
    const secureFlag = "Secure";
    const cookieString = `${name}=${value}; domain=${cookieDomain}; path=/; SameSite=None; ${secureFlag}`;
    document.cookie = cookieString;
  };
  setCookie("mpaForm", JSON.stringify(params.get("continue")));
  console.log(params.get("continue"));
  useEffect(() => {
    const handleLogout = async () => {
      await logout();
    };
    if (
      !currentPathname.includes("/auth") &&
      !currentPathname.includes("/login") &&
      !currentPathname.includes("/account") &&
      !currentPathname.includes("/resend-mail")
    ) {
      handleLogout();
    }
  }, [currentPathname, user, logout, navigate]);

  localStorage.setItem("user", JSON.stringify(user));

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<RegisterAuth />} />
        <Route path="/auth" element={<ResendEmail />} />
        <Route path="/account" element={<Account />} />
        <Route path="/confirmation" element={<Confirmation />} />
        {/* <Route path="/login" element={<Login />} /> */}

        <Route path={AppRoutes.LoginAppSellWith} element={<Login />} />
        <Route path={AppRoutes.LoginAppBuyWith} element={<Login />} />
        <Route path={AppRoutes.LoginAdmin} element={<Login />} />
        <Route path={AppRoutes.LoginAppSellWithLimitedUser} element={<Login />} />
        <Route path={AppRoutes.LoginAppBuyWithLimitedUser} element={<Login />} />

        {/* <Route path="/login/app/sellwith" element={<Login />} />
        <Route path="/login/app/buywith" element={<Login />} />
        <Route path="/login/admin" element={<Login />} />
        <Route path="/login/app/sellwith?register=limited_user" element={<Login />} />
        <Route path="/login/app/buywith?register=limited_user" element={<Login />} /> */}

        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<RegisterAuth />} />

        {/* <Route path="/resend-mail" element={<ResendEmail />} /> */}
        <Route path="/signup" element={<SignUp />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
