// errorActions.ts or a similar utility file

import { CK_AUTH_TOKEN, CK_CUSTOM_PARAM, CK_GW_TYPE, CK_MPA_FORM, CK_REFRESH_TOKEN, CK_USER_CLAIMS, CK_USER_EMAIL } from "./cookieDefinitions";
import { GWCookies } from "./cookies";

/**
 * Clears all cookies for the current domain.
 * Following same pattern as scalewith-ui to remove cookies client side even with secure cookie limitation...
 */
const clearCookies = () => {
    GWCookies.remove(CK_AUTH_TOKEN)
    GWCookies.remove(CK_REFRESH_TOKEN)
    GWCookies.remove(CK_GW_TYPE)
    GWCookies.remove(CK_USER_CLAIMS)
    GWCookies.remove(CK_USER_EMAIL)
    GWCookies.remove(CK_MPA_FORM)
    GWCookies.remove(CK_CUSTOM_PARAM)
};

/**
 * Clears all data from localStorage.
 */
const clearLocalStorage = () => {
    localStorage.clear();
};

/**
 * Redirects the user to Auth0 Logout url
 * https://stackoverflow.com/a/57006227
 * @param url The URL to redirect to.
 */
const redirectToAuth0LogoutURL = (url: string) => {
    window.location.href = `https://${url}/v2/logout`;
};


/**
 * Handles common error actions like clearing cookies, clearing localStorage, and redirecting.
 * @param redirectUrl The URL to redirect to after performing the error actions.
 */
export const handleErrorActions = (redirectUrl: string) => {
    clearCookies();
    clearLocalStorage();
    redirectToAuth0LogoutURL(redirectUrl);
};
