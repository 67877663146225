import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { handleErrorActions } from "../../shared/errorActions";

export const getCompanies = async (company: string) => {
  const path = process.env.REACT_APP_LOCALHOST ? '' : '/portal/v1'
  const getTokenKey = process.env.REACT_APP_ENV_TYPE !== "prod"
  ? `${process.env.REACT_APP_ENV_TYPE}_gw_auth_token`
  : `gw_auth_token`;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${path}/company/registration?name=${company}&count=10&offset=0&orderBy=name&descending=false`,
      {
        headers: {
          Authorization: `${Cookies.get(getTokenKey)}`,
        },
      }
    );
    return response;
  } catch (error) {
    toast.error("Company already exists");
    handleErrorActions(process.env.REACT_APP_AUTH0_DOMAIN as string)
    return null;
  }
};
