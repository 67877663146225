import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box, Typography, useMediaQuery, Link } from "@mui/material";
import { FlexBox } from "../../shared/components/FlexBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import { ReactComponent as ScaleWithLogo } from "../../assets/images/svg/scalewith-logo.svg";
import LeftBox from "./LeftBox";
import blueBgG from "../../assets/images/svg/bg.svg";
import mailIcon from "../../assets/images/svg/Mail.svg";
import { getUserInfo } from "../actions/getUserInfo";
import Cookies from "js-cookie";
import { handleErrorActions } from "../../shared/errorActions";
import LoadingSpinner from "../../shared/components/LoadingSpinner";

export default function ResendEmail() {
  const currentOrigin = window.location.origin;
  const is1300 = useMediaQuery("(max-width:1300px)");
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const [data, setData] = useState<any>();
  // const [errorMsg, setErrorMsg] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [isPollingUser, setIsPollingUser] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const errorDescription = queryParams.get("error_description");
  const errorType = queryParams.get("error");
  const generatedState = queryParams.get("generatedState");

  const getUserTokenDetails = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      // setUserClaims(tokenClaims);
      getUserInfo(accessToken).then((userInfo) => {
        setData(userInfo?.data);
      });
    } catch (error) {
      toast.error("Error");
    }
  };

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const email = params.get("user_email");
    if (email) {
      setIsLoading(false);
      setUserEmail(email);
    }
    if (
      queryParams.has("error") &&
      errorType !== "access_denied" &&
      generatedState === localStorage.getItem("generatedState")
    ) {
      localStorage.clear();
      setIsLoading(false);
      setShowErrorPage(true);
    } else if (
      queryParams.has("error") &&
      errorType === "access_denied" &&
      errorDescription !== "email_not_verified" &&
      generatedState === localStorage.getItem("generatedState")
    ) {
      localStorage.clear();
      setIsLoading(false);
      setShowErrorPage(true);
    } else if (
      queryParams.has("error") &&
      errorDescription === "email_not_verified" &&
      generatedState === localStorage.getItem("generatedState")
    ) {
      localStorage.clear();
      setIsLoading(false);
    }

    if (window.performance.navigation.type === 1) {
      localStorage.clear();
      logout();
    }

    // const timeout = setTimeout(() => {
    //   setIsLoading(false);
    //   if (localStorage.getItem("user") === "undefined") {
    //     logout();
    //   }
    // }, 3000);
    // return () => {
    //   clearTimeout(timeout);
    // };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isAuthenticated) {
      setData(user);
      getUserTokenDetails();
    }
  }, [isAuthenticated]);

  const setCookie = (name: string, value: string) => {
    const cookieName =
      process.env.REACT_APP_ENV_TYPE !== "prod"
        ? `${process.env.REACT_APP_ENV_TYPE}_${name}`
        : name;
    const cookieDomain = process.env.REACT_APP_LOCALHOST
      ? `${process.env.REACT_APP_ENV_TYPE}.localhost`
      : `.${process.env.REACT_APP_BASE_DOMAIN}`;
    const secureFlag = "Secure";
    const cookieString = `${cookieName}=${value}; domain=${cookieDomain}; path=/; SameSite=None; ${secureFlag}`;
    document.cookie = cookieString;
  };

  const removeCookie = (name: string) => {
    const cookieName =
      process.env.REACT_APP_ENV_TYPE !== "prod"
        ? `${process.env.REACT_APP_ENV_TYPE}_${name}`
        : name;
    const cookieDomain = process.env.REACT_APP_LOCALHOST
      ? `${process.env.REACT_APP_ENV_TYPE}.localhost`
      : `.${process.env.REACT_APP_BASE_DOMAIN}`;

    const pastExpirationDate = new Date(0).toUTCString();
    document.cookie = `${cookieName}=;expires=${pastExpirationDate};domain=${cookieDomain};path=/`;
  };

  const sendVerificationEmail = async () => {
    // const userInfo: any = localStorage.getItem("user");
    // const parsedInfo = JSON.parse(userInfo);
    const path = process.env.REACT_APP_LOCALHOST ? "" : "/portal/v1";
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${path}/users/resend-verification-email`,
        {
          username: userEmail,
        }
      );
      if (response) {
        toast.success("Verification email sent successfully.");
      }
    } catch (error) {
      toast.error("Error resending verification email");
      handleErrorActions(process.env.REACT_APP_AUTH0_DOMAIN as string);
    }
  };

  // Cannot touch this as part of error handling..
  // Upon Initial sign up, this almost expects to get a 401 error...
  // There is way too much going on here to understand what is going on, but I'm going to need to trust it's here for a reason.
  const getUserMetaInfo = async () => {
    const accessToken = await getAccessTokenSilently();
    const path = process.env.REACT_APP_LOCALHOST ? "" : "/portal/v1";

    const startTime = new Date().getTime();
    let response;
    const timeout = 30000; // 30 seconds timeout

    while (new Date().getTime() - startTime < timeout) {
      try {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}${path}/users/register`,
          {
            params: { username: data?.email, sub: data?.sub },
            headers: { Authorization: accessToken },
          }
        );

        if (response && response.status === 200) {
          break; // Successful response, break out of the loop
        }
      } catch (error) {
        if (!isPollingUser) {
          setIsPollingUser(true); // Show spinner before starting the polling
        }
        // For other errors or status codes, continue polling
      }

      await new Promise((resolve) => setTimeout(resolve, 1500)); // Wait 1 second before retrying
    }

    if (isPollingUser) {
      setIsPollingUser(false);
    } // Hide spinner after polling is done

    // Continue with your existing logic here, using the `response` variable
    if (response) {
      if (
        response?.data?.tenantAccessGrants &&
        response.data.tenantAccessGrants.length > 0
      ) {
        setCookie("gw_auth_token", accessToken);
        setCookie("user_claims_details", JSON.stringify(response?.data));

        // if we are localhost:
        if (process.env.REACT_APP_LOCALHOST) {
          window.location.href = "http://dev.localhost:3000";
          return;
        }

        if (response?.data?.domain === "") {
          setIsLoading(false);
          const selectedAppType = (response?.data?.app).toLowerCase();
          const app =
            process.env.REACT_APP_ENV_TYPE !== "prod"
              ? `${process.env.REACT_APP_ENV_TYPE}.${selectedAppType}.v1`
              : `${selectedAppType}`;

          window.location.href = `https://${app}.scalewith.com`;
        } else {
          setIsLoading(false);
          window.location.href = `https://${response?.data?.domain}.scalewith.com`;
        }
      } else {
        if (
          response?.data?.userType === "sio_user" &&
          Cookies.get("customParam") !== undefined
        ) {
          localStorage.clear();
          setIsLoading(false);
          setCookie("gw_auth_token", accessToken);
          setCookie("user_claims_details", JSON.stringify(response?.data));
          // if we are localhost:
          if (process.env.REACT_APP_LOCALHOST) {
            window.location.href = process.env
              .REACT_APP_SCALEWITH_UI_FULL_LOCALHOST_DOMAIN as string;
          }
          window.location.href = `${Cookies.get("customParam")}`;
        } else {
          logout();
        }
      }

      if (
        response.status === 404 ||
        response.status === 500 ||
        response.status === 401
      ) {
        setIsLoading(false);
        setCookie("gw_auth_token", accessToken);
        setCookie("user_email", btoa(data?.email));
        window.location.href = `${currentOrigin}/account`;
      } else if (response.status >= 400) {
        // This will cover any other error status codes
        // looks like there's already some logic for at least logging out here...
        toast.error(response.data.message);
        logout();
        handleErrorActions(process.env.REACT_APP_AUTH0_DOMAIN as string);
      }
    }
  };

  useEffect(() => {
    if (data?.email_verified) {
      setIsEmailVerified(true);
      setIsLoading(true);
      getUserMetaInfo();
    }
  }, [data]);

  return (
    <Grid container columns={{ md: 12 }} className="resendMail">
      <Grid
        xs={4}
        sx={{
          backgroundImage: `url(${
            !showErrorPage
              ? blueBgG
              : "https://static.scalewith.com/assets/v1/img/jpg/side-panel-bg-tekhisolar1-1200.c3a221fd8b.jpg"
          })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignContent: "center",
          color: "#FFFFFF",
        }}
      >
        <LeftBox
          steps={[{ stepIndex: 0, isActivated: true }]}
          showError={showErrorPage}
        />
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        xs={8}
        sx={{
          position: "relative",
          backgroundColor: "#FFFFFF",
        }}
      >
        {!isLoading && !isEmailVerified && (
          <ScaleWithLogo
            width="320px"
            height="86px"
            style={{ marginTop: "2em" }}
          />
        )}
        {isLoading && isPollingUser && <LoadingSpinner />}
        {isLoading && !isPollingUser && <BounceLoader />}
        {!isLoading && !isEmailVerified && (
          <>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                display: "flex",
                border: "1px solid #EAEAEA",
                justifyContent: "center",
                alignItems: "center",
                gap: "40px",
                marginTop: "20px",
                padding: `${is1300 ? "15px" : ""}`,
                margin: "5em 0 12em 0",
              }}
            >
              <Box sx={{ width: "600px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "1em",
                  }}
                >
                  {!showErrorPage ? (
                    <img src={mailIcon} alt="Mail" />
                  ) : (
                    <HighlightOffIcon fontSize="large" />
                  )}
                </div>
                <Typography
                  sx={{
                    font: "inter",
                    color: "#4F4F4F",
                    textAlign: "center",
                  }}
                >
                  {!showErrorPage && (
                    <h2
                      style={{
                        fontSize: "24px",
                        fontWeight: "700",
                        color: "#57585A",
                      }}
                    >
                      Verify Your Email
                    </h2>
                  )}
                  {!showErrorPage ? (
                    <p style={{ fontSize: "16px", fontWeight: "400" }}>
                      You’re one step closer to helping make the world a better
                      place! Check your email to verify your account by clicking
                      the link in and complete your account setup.
                    </p>
                  ) : (
                    <>
                      <p style={{ fontSize: "16px", fontWeight: "400" }}>
                        We are unable to process your login request at this
                        time. Please try again later.
                      </p>
                      <p style={{ fontSize: "16px", fontWeight: "400" }}>
                        Contact{" "}
                        <Link
                          href="mailto:help@scalewith.com"
                          style={{ color: "#337ab7" }}
                        >
                          help@scalewith.com
                        </Link>{" "}
                        if the problem persists
                      </p>
                      <p style={{ fontSize: "16px", fontWeight: "400" }}>
                        Description:&nbsp;{errorDescription}
                      </p>
                      <p>
                        <Typography
                          onClick={() => {
                            removeCookie("gw_auth_token");
                            removeCookie("user_email");
                            localStorage.clear();
                            logout();
                          }}
                          sx={{
                            color: "#337ab7",
                            cursor: "pointer",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Back to Login
                        </Typography>
                      </p>
                    </>
                  )}
                </Typography>
                {!showErrorPage && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "3em 0 2em 0",
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#57585A",
                    }}
                  >
                    If you did not receive the email, please{" "}
                    <Link
                      onClick={() => sendVerificationEmail()}
                      sx={{ paddingLeft: "4px", cursor: "pointer" }}
                    >
                      click here
                    </Link>
                    .
                  </div>
                )}
              </Box>
            </Box>
          </>
        )}
        <div
          style={{
            width: "65%",
            position: "fixed",
            bottom: "0",
          }}
        >
          <FlexBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1em",
              backgroundColor: "#FFFFFF",
              borderTop: "1px solid #E0E0E0",
              height: "40px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "#57585A", display: "flex", alignItems: "center" }}
            >
              <img
                src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-120.8b82fd9357.png"
                alt="scalewith"
                style={{ height: "25px", marginRight: "5px" }}
              />
              <span>© 2024 ScaleWith, Inc. All rights reserved.</span>
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#2498cb", display: "flex", alignItems: "center" }}
            >
              <Link
                href="https://www.scalewith.com/terms-and-conditions/"
                target="_blank"
                sx={{
                  color: "#2498cb",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Terms of Use
              </Link>
              <span style={{ margin: "0 10px" }}></span>
              <Link
                href="https://www.scalewith.com/privacy-policy/"
                target="_blank"
                sx={{
                  color: "#2498cb",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Privacy Policy
              </Link>
              <span style={{ margin: "0 10px" }}></span>
              <Link
                href="https://www.scalewith.com/terms-and-conditions/"
                target="_blank"
                sx={{
                  color: "#2498cb",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Cookie Policy
              </Link>
              <span style={{ margin: "0 10px" }}></span>
              <Link
                href="#"
                // target="_blank"
                sx={{
                  color: "#2498cb",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Contact Us
              </Link>
            </Typography>
          </FlexBox>
        </div>
      </Grid>
    </Grid>
  );
}
