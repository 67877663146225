import { useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
// import { BounceLoader } from "react-spinners";

const SignUp = () => {
  const { loginWithRedirect } = useMemo(useAuth0, []);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: { 
        screen_hint: 'signup',
        redirect_uri: `${window.location.origin}/auth`
      },
      fragment: '',
    });
  }, [loginWithRedirect]);

  return (
    <Box sx={style}>
      <Box sx={{ display: "flex" }}>
        {/* <BounceLoader /> */}
      </Box>
    </Box>
  );
};

export default SignUp;
