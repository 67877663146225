import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "@fontsource/montserrat";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Routes } from './routePath';

if (window.location.hostname === 'localhost') {
  const newHost = 'dev.localhost';
  window.location.href = window.location.href.replace('localhost', newHost);
}

const currentOrigin = window.location.origin;

const setCookie = (name: string, value: string) => {
  const cookieDomain = process.env.REACT_APP_LOCALHOST ? `${process.env.REACT_APP_ENV_TYPE}.localhost` : `.${process.env.REACT_APP_BASE_DOMAIN}`;
  const secureFlag = "Secure";
  const cookieString = `${name}=${value}; domain=${cookieDomain}; path=/; SameSite=None; ${secureFlag}`;
  document.cookie = cookieString;
};

const generateUniqueState = (): string => {
  const randomValue = Math.random().toString(36).substring(2);
  const additionalChars = 36 - randomValue.length;
  const randomSuffix = Math.random().toString(36).substring(2, 2 + additionalChars);
  return `${randomValue}${randomSuffix}`;
};

const nonGuessableValue = generateUniqueState();
if (localStorage.getItem('generatedState') === null) {
  localStorage.setItem("generatedState", nonGuessableValue);
}

const onRedirectCallback = (appState: any) => {
  window.history.pushState({}, `${currentOrigin}/auth`);
  console.log('customParam', appState.customParam);
  setCookie('customParam', appState.customParam);
};


// Function to set clientId based on the URL path and url params from env 
const setClientIdBasedOnPathParameters = () => {
  const pathname = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);
  const registerParam = searchParams.get("register");

  console.log(`loc:getClientId msg:Setting clientId for ${pathname} with ${registerParam} params`);

  if (pathname.startsWith(Routes.LoginAppSellWith)) {
    if (registerParam === 'limited_user') {
      return `${process.env.REACT_APP_AUTH0_CLIENT_ID_SELLWITH_LIMITED}`;
    }
    return `${process.env.REACT_APP_AUTH0_CLIENT_ID_SELLWITH}`;
  } else if (pathname.startsWith(Routes.LoginAppBuyWith)) {
    if (registerParam === 'limited_user') {
      return `${process.env.REACT_APP_AUTH0_CLIENT_ID_BUYWITH_LIMITED}`;
    }
    return `${process.env.REACT_APP_AUTH0_CLIENT_ID_BUYWITH}`;
  } else if (pathname.startsWith(Routes.LoginAdmin)) {
    return `${process.env.REACT_APP_AUTH0_CLIENT_ID_ADMIN}`;
  } else {
    return `${process.env.REACT_APP_AUTH0_CLIENT_ID_DEFAULT}`;
  }
};

const providerConfig = {
  domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
  clientId: setClientIdBasedOnPathParameters(),
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: `${currentOrigin}/auth?generatedState=${nonGuessableValue}`,
    ...(process.env.REACT_APP_AUTH0_AUDIENCE
      ? { audience: process.env.REACT_APP_AUTH0_AUDIENCE }
      : null),
  },
  useRefreshTokens: true,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Auth0Provider {...providerConfig}>
          <App />
        </Auth0Provider>
      </LocalizationProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
