import React, { useState, useEffect } from 'react';
import { CircularProgress, Box, Typography, Fade } from '@mui/material';

const messages = [
  "Setting up your account...",
  "Preparing your workspace...",
  "Finalizing user settings..."
];

const LoadingSpinner: React.FC = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setFade(true);
      }, 500); // Change the text after a fade out
    }, 2500); // Total cycle duration

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box textAlign="center">
      <CircularProgress />
      <Fade in={fade} timeout={500}>
        <Typography variant="body1"
              sx={{ color: "#57585A", display: "flex", alignItems: "center" }}
          >{messages[messageIndex]}</Typography>
      </Fade>
    </Box>
  );
};

export default LoadingSpinner;
