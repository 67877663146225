import axios from "axios";
import { toast } from "react-toastify";
import { handleErrorActions } from "../../shared/errorActions";

export const getIndustries = async() => {
    const path = process.env.REACT_APP_LOCALHOST ? '' : '/portal/v1'

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${path}/accounts/industries`
      );
      return response;
    } catch (error) {
      toast.error('Error');
      handleErrorActions(process.env.REACT_APP_AUTH0_DOMAIN as string)
      return null;
    }
  }