import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { BounceLoader } from "react-spinners";
import Cookies from "js-cookie";

const RegisterAuth = () => {
  const { loginWithRedirect } = useAuth0();
  console.log(Cookies.get("mpaForm"));
  const customParams = {
    customParam: Cookies.get("mpaForm"),
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };

  useEffect(() => {
    loginWithRedirect({
      appState: customParams,
    });
  }, []);

  return (
    <Box sx={style}>
      <Box sx={{ display: "flex" }}>
        <BounceLoader />
      </Box>
    </Box>
  );
};

export default RegisterAuth;
