import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

const Logout = () => {
  const { logout } = useAuth0();
  useEffect(() => {
    logout();
  }, []);
  return <Button onClick={() => logout()}>Logout</Button>;
};

export default Logout;
