import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Link,
  Box,
  Button,
  Checkbox,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { FlexBox } from "../../shared/components/FlexBox";
import { toast } from "react-toastify";
import LeftBox from "./LeftBox";
// import Logout from "./Logout";
import PersonIcon from "../../assets/images/svg/person-icon.svg";
import CompanyIcon from "../../assets/images/svg/company-icon.svg";
import { ReactComponent as ScaleWithLogo } from "../../assets/images/svg/scalewith-logo.svg";
// import blueBgG from "../../assets/images/svg/SideBluePanelBG.svg";
import blueBgG from "../../assets/images/svg/bg.svg";

import UserAccount from "./UserAccount";
import { getIndustries } from "../actions/getIndustries";
import { getCompanies } from "../actions/getCompanies";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { handleErrorActions } from "../../shared/errorActions";

const TERMS_OF_USE_URL = "https://www.scalewith.com/terms-and-conditions/";
const PRIVACY_POLICY_URL = "https://www.scalewith.com/privacy-policy/";

const inputStyles = {
  root: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: 0,
      fontSize: "16px !important",
      fontWeight: 400,
      color: "#000000 !important",
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      lineHeight: "20px",
    },
    width: "95%",
  },
  title: {
    color: "#57585A",
    fontSize: "14px !important",
    fontWeight: 600,
    lineHeight: "19.07px",
    marginBottom: "3px",
  },
  box: {
    height: "47px",
    width: "50px",
    lineHeight: "2",
    textAlign: "center",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "MuiInputBase-adornedStart": { paddingLeft: 0 },
  },
};

const autoCompleteStyles = {
  root: {
    "& .MuiOutlinedInput-root": {
      padding: "1px !important",
      width: "95% !important",
    },
    "& .MuiAutocomplete-input": {
      padding: "7.5px 32px 7.5px 0px !important",
    },
  },
};

const Account = () => {
  const { logout } = useAuth0();
  const is1900 = useMediaQuery("(max-width:1900px)");
  // const queryParams = new URLSearchParams(window.location.search);
  // const generatedState = queryParams.get("state");
  const [industriesList, setIndustriesList] = useState([]);
  const selectOptions =
    industriesList &&
    industriesList.map((option: any) => (
      <MenuItem key={option.name} value={option.name}>
        {option.name}
      </MenuItem>
    ));

  const [userData, setuserData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    industry: "",
  });

  const [isPrivacy, setIsPrivacy] = useState(false);
  const [activeId, setActiveId] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [appType, setAppType] = useState("");
  const [selectedValue, setSelectedValue] = useState("US");
  const [temporaryCompanies, settemporaryCompanies] = useState([]);
  const [currentUser, setCurrentUser] = useState<any>({
    userId: "",
    email: "",
  });
  const [industryValues, setIndustryValues] = useState({
    subIndustry: "",
    industry: "",
  });
  const [display, setDisplay] = useState(false);

  const [steps, setSteps] = useState([{ stepIndex: 0, isActivated: true }]);
  const [companies, setCompanies] = useState([]);
  const getCompaniesList = (value: string) => {
    getCompanies(value).then((companies) => {
      settemporaryCompanies(companies?.data?.results);
      const companiesArray = companies?.data?.results.map(
        (company: any) => company.name
      );
      setCompanies(companiesArray);
    });
  };

  useEffect(() => {
    if (isActive) {
      setSteps([...steps, { stepIndex: 1, isActivated: true }]);
    } else {
      setSteps([...steps, { stepIndex: 1, isActivated: false }]);
    }
  }, [isActive]);

  const formValues = (value: string, key: string) => {
    setuserData((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const getTokenKey = process.env.REACT_APP_ENV_TYPE !== "prod"
  ? `${process.env.REACT_APP_ENV_TYPE}_gw_auth_token`
  : `gw_auth_token`;
  const getUserEmail = process.env.REACT_APP_ENV_TYPE !== "prod"
  ? `${process.env.REACT_APP_ENV_TYPE}_user_email`
  : `user_email`;

  const checkAndLogout = () => {
    try {
      if (!Cookies.get(getTokenKey)) {
        logout();
      } else {
        const token: any = jwtDecode(`${Cookies.get(getTokenKey)}`);
        const userAuthId = token?.sub;
        const userEmail = atob(`${Cookies.get(getUserEmail)}`);
        setCurrentUser({
          userId: userAuthId,
          email: userEmail,
        });
      }
    } catch (error) {
      console.error("Error checking cookies:", error);
    }
  };

  useEffect(() => {
    checkAndLogout();
    getIndustries().then((industries) => {
      setIndustriesList(industries?.data);
    });
    // if(localStorage.getItem("generatedState") !== generatedState){
    //   localStorage.clear();
    //   logout();
    // }
  }, []);

  useEffect(() => {
    const selectedCompany: any = temporaryCompanies.filter(
      (company: any) => company.name === userData?.company
    );
    if (selectedCompany.length > 0) {
      setDisplay(true);
      setIndustryValues({
        industry: selectedCompany[0]?.sector,
        subIndustry: selectedCompany[0]?.industry,
      });
    } else {
      setDisplay(false);
      setIndustryValues({
        industry: "",
        subIndustry: "",
      });
    }
  }, [userData?.company]);

  const setCookie = (name: string, value: string) => {
    const cookieName = process.env.REACT_APP_ENV_TYPE !== "prod" ? `${process.env.REACT_APP_ENV_TYPE}_${name}` : name;
    const cookieDomain = process.env.REACT_APP_LOCALHOST ? `${process.env.REACT_APP_ENV_TYPE}.localhost` : `.${process.env.REACT_APP_BASE_DOMAIN}`;
    const secureFlag = "Secure";
    const cookieString = `${cookieName}=${value}; domain=${cookieDomain}; path=/; SameSite=None; ${secureFlag}`;
    document.cookie = cookieString;
  };

  const removeCookie = (name: string) => {
    const cookieDomain = process.env.REACT_APP_LOCALHOST ? `${process.env.REACT_APP_ENV_TYPE}.localhost` : `.${process.env.REACT_APP_BASE_DOMAIN}`;

    const pastExpirationDate = new Date(0).toUTCString();
    document.cookie = `${name}=;expires=${pastExpirationDate};domain=${cookieDomain};path=/`;
  };

  const isNullOrWhitespace = (value?: string | null): boolean => {
    return !value || value.trim().length === 0 || value === null;
  };

  const isRegisterValid = () => {
    const { firstName, lastName, company } = userData;
    return (
      !isNullOrWhitespace(firstName) &&
      !isNullOrWhitespace(lastName) &&
      !isNullOrWhitespace(company) &&
      !isNullOrWhitespace(industryValues?.subIndustry) &&
      isPrivacy
    );
  };

  const findNameBySubIndustry = (targetSubIndustry: string) => {
    let result: string | undefined = undefined;
    industriesList.forEach((item: any) => {
      item.items.forEach((subItem: any) => {
        if (subItem.subIndustry === targetSubIndustry) {
          result = item.name;
        }
      });
    });
    return result || "Not found";
  };

  const setUserData = async () => {
    const path = process.env.REACT_APP_LOCALHOST ? '' : '/portal/v1'
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${path}/users/register`,
        {
          user: {
            username: currentUser?.email,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            externalAuthId: currentUser?.userId,
          },

          account: {
            industry: {
              industry: industryValues?.industry,
              subIndustry: industryValues?.subIndustry,
            },

            company: {
              name: userData?.company,
            },
          },
          productType: appType,
          locale: selectedValue,
        },
        {
          headers: {
            Authorization: `${Cookies.get(getTokenKey)}`,
          },
        }
      );
      toast.success("Data updated successfully.");
      if (response?.data) {
        setCookie("user_claims_details", JSON.stringify(response?.data));
        if (response?.data?.domain === "") {
          const selectedAppType = appType.toLowerCase();
          const app =
            process.env.REACT_APP_ENV_TYPE !== "prod"
              ? `${process.env.REACT_APP_ENV_TYPE}.${selectedAppType}.v1`
              : `${selectedAppType}`;
          window.location.href = `https://${app}.scalewith.com`;
        } else {
          window.location.href = `https://${response?.data?.domain}.scalewith.com`;
        }
      } else {
        toast.error("Update process failed.");
      }
    } catch (error: any) {
      if (error?.response) {
        toast.error(error?.response?.data?.message);
        handleErrorActions(process.env.REACT_APP_AUTH0_DOMAIN as string)
      }
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  function capitalizeEveryWord(input: string): string {
    return input
      .split(" ")
      .map((word) => {
        if (word.length === 0) return ""; // Handle empty strings
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  return (
    <Grid container columns={{ md: 12 }} className="resendMail">
      <Grid
        xs={4}
        sx={{
          backgroundImage: `url(${blueBgG})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignContent: "center",
          color: "#FFFFFF",
        }}
      >
        <LeftBox steps={steps} activeId={activeId} />
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        height={`${isActive ? (!is1900 ? "97vh" : "94vh") : "90vh"}`}
        xs={8}
        sx={{
          position: "relative",
          // backgroundColor: `${isActive ? "#FFFFFF" : "#E0E0E0"}`,
          backgroundColor: "#FFFFFF",
          top: `${isActive ? "40px" : "0px"}`,
        }}
      >
        <ScaleWithLogo
          width="232px"
          height="60px"
          style={{ marginBottom: "4em", objectFit: "contain", marginTop: `${is1900 ? '0px' : '12em'}` }}
        />
        {!isActive ? (
          <UserAccount
            selectedId={activeId}
            setAppType={(appType) => setAppType(appType)}
            setActiveId={(selectedId) => setActiveId(selectedId)}
            setIsActive={() => setIsActive(true)}
            logOut={() => {
              removeCookie(getTokenKey);
              removeCookie(getUserEmail);
              logout();
            }}
          />
        ) : (
          <>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                color: "#57585A",
                lineHeight: "38.4px",
                marginBottom: "15px",
              }}
            >
              Get Ready to Explore {capitalizeEveryWord(appType)}
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#57585A",
                lineHeight: "21.79px",
                marginBottom: "50px",
              }}
            >
              Let’s get to know you better! Confirm your details below to
              complete your account setup.
            </Typography>
            <FlexBox
              sx={{
                display: "flex",
                flexDiection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                paddingBottom: "4em",
              }}
            >
              <Box
                sx={{
                  width: "660px",
                  height: "210px",
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "40px",
                  paddingTop: "5em",
                }}
              >
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={inputStyles.title}>
                      First Name{" "}
                    </Typography>
                    <TextField
                      placeholder="John"
                      //   value = "xyz"
                      type="text"
                      onChange={(e) => formValues(e.target.value, "firstName")}
                      variant="outlined"
                      size="small"
                      sx={inputStyles.root}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box sx={inputStyles.box}>
                              <img
                                src={PersonIcon}
                                alt=""
                                height="18px"
                                width="16px"
                                style={{ marginTop: "15px" }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={inputStyles.title}>
                      Last Name{" "}
                    </Typography>
                    <TextField
                      placeholder="Deo"
                      // value="xyz"
                      type="text"
                      onChange={(e) => formValues(e.target.value, "lastName")}
                      variant="outlined"
                      size="small"
                      sx={inputStyles.root}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box sx={inputStyles.box}>
                              {" "}
                              <img
                                src={PersonIcon}
                                alt=""
                                height="18px"
                                width="16px"
                                style={{ marginTop: "15px" }}
                              />
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={inputStyles.title}>
                      Company{" "}
                    </Typography>
                    <Autocomplete
                      freeSolo
                      disableClearable
                      options={companies}
                      sx={autoCompleteStyles.root}
                      onInputChange={(event, newInputValue) => {
                        getCompaniesList(newInputValue);
                        formValues(newInputValue, "company");
                        setDisplay(false);
                        setIndustryValues({
                          subIndustry: "",
                          industry: "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Company"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box sx={inputStyles.box}>
                                  <img
                                    src={CompanyIcon}
                                    alt=""
                                    height="18px"
                                    width="16px"
                                    style={{ marginTop: "15px" }}
                                  />
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={inputStyles.title}>
                      Industry
                    </Typography>
                    {!display ? (
                      <Autocomplete
                        id="grouped-industries"
                        disableClearable
                        options={industriesList.flatMap((option: any) =>
                          option.items
                            ? option.items.map((subcat: any) => ({
                                name: option.name,
                                displayLabel: subcat.displayLabel,
                                subIndustry: subcat.subIndustry,
                              }))
                            : option
                        )}
                        groupBy={(option) => option.name}
                        getOptionLabel={(option) =>
                          option.subIndustry || option.name
                        }
                        onInputChange={(event, newInputValue) => {
                          const industryName =
                            findNameBySubIndustry(newInputValue);
                          setIndustryValues({
                            subIndustry: newInputValue ? newInputValue : "",
                            industry: industryName ? industryName : "",
                          });
                        }}
                        sx={autoCompleteStyles.root}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Industry"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Box sx={inputStyles.box}>
                                    <img
                                      src={CompanyIcon}
                                      alt=""
                                      height="18px"
                                      width="16px"
                                      style={{ marginTop: "15px" }}
                                    />
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        value={industryValues?.subIndustry}
                        disabled
                        type="text"
                        variant="outlined"
                        size="small"
                        sx={inputStyles.root}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box sx={inputStyles.box}>
                                <img
                                  src={CompanyIcon}
                                  alt=""
                                  height="18px"
                                  width="16px"
                                  style={{ marginTop: "15px" }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Grid>
                  <Grid xs={12} sm={12}>
                    <Typography variant="subtitle1" sx={inputStyles.title}>
                      Select Country{" "}
                    </Typography>
                    <RadioGroup
                      aria-label="country"
                      name="country"
                      value={selectedValue}
                      onChange={handleRadioChange}
                      row
                      sx={{
                        color: "#2089B5",
                        "&.Mui-checked": {
                          color: "#2089B5",
                          backgroundColor: "#2089B5",
                        },
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          color: "#57585A",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        value="US"
                        control={<Radio />}
                        label="US"
                      />
                      <FormControlLabel
                        sx={{
                          color: "#57585A",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        value="UK"
                        control={<Radio />}
                        label="UK"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid xs={12} sm={12} style={{ marginLeft: "-12px" }}>
                    <Checkbox
                      name="check"
                      onChange={() => setIsPrivacy(!isPrivacy)}
                    />
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={inputStyles.title}
                    >
                      I agree with the{" "}
                      <Link
                        href={TERMS_OF_USE_URL}
                        target="_blank"
                        sx={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            color: "#2089B5",
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "inter, sans-serif !important",
                          }}
                        >
                          Terms & Conditions
                        </span>
                      </Link>{" "}
                      and{" "}
                      <Link
                        href={PRIVACY_POLICY_URL}
                        target="_blank"
                        sx={{ textDecoration: "none" }}
                      >
                        <span
                          style={{
                            color: "#2089B5",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          Privacy Policy
                        </span>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </FlexBox>

            <Grid
              container
              xs={12}
              sm={8}
              gap={5}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "auto 0",
                width: "60%",
                marginTop: "-6px",
              }}
            >
              <Grid xs={5}>
                <Button
                  sx={{
                    color: "#2089B5",
                    width: "100%",
                    backgroundColor: "FFFFFF",
                    fontSize: "16px",
                    fontWeight: "700",
                    border: "1px solid #2089B5",
                    borderRadius: "8px",
                    padding: "10px 24px",
                    marginBottom: "10px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => setIsActive(false)}
                >
                  Back
                </Button>
              </Grid>
              <Grid xs={6}>
                <Button
                  sx={{
                    color: `${
                      !isRegisterValid()
                        ? "#ffffff !important"
                        : "#FFFFFF !important"
                    }`,
                    width: "100%",
                    backgroundColor: `${
                      !isRegisterValid()
                        ? "#6B7280 !important"
                        : "#2089B5 !important"
                    }`,
                    fontSize: "18px",
                    fontWeight: "500",
                    border: "1px solid #DFE2E6",
                    borderRadius: "8px",
                    padding: "10px 24px",
                    marginBottom: "10px",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  size="medium"
                  onClick={setUserData}
                  disabled={!isRegisterValid()}
                >
                  Get Started with {appType}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        <div
          style={{
            width: "65%",
            position: "fixed",
            bottom: "0",
          }}
        >
          <FlexBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1em",
              backgroundColor: "#FFFFFF",
              borderTop: "1px solid #E0E0E0",
              height: "40px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "#57585A", display: "flex", alignItems: "center" }}
            >
              <img
                src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-120.8b82fd9357.png"
                alt="scalewith"
                style={{ height: "25px", marginRight: "5px" }}
              />
              <span>© 2024 ScaleWith, Inc. All rights reserved.</span>
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#2498cb", display: "flex", alignItems: "center" }}
            >
              <Link
                href="https://www.scalewith.com/terms-and-conditions/"
                target="_blank"
                sx={{ color: "#2498cb", textDecoration: "none", fontWeight: 'bold' }}
              >
                Terms of Use
              </Link>
              <span style={{ margin: "0 10px", }}></span>
              <Link
                href="https://www.scalewith.com/privacy-policy/"
                target="_blank"
                sx={{ color: "#2498cb", textDecoration: "none", fontWeight: 'bold' }}
              >
                Privacy Policy
              </Link>
              <span style={{ margin: "0 10px", }}></span>
              <Link
                href="https://www.scalewith.com/terms-and-conditions/"
                target="_blank"
                sx={{ color: "#2498cb", textDecoration: "none", fontWeight: 'bold' }}
              >
                Cookie Policy
              </Link>
              <span style={{ margin: "0 10px", }}></span>
              <Link
                href="#"
                // target="_blank"
                sx={{ color: "#2498cb", textDecoration: "none", fontWeight: 'bold' }}
              >
                Contact Us
              </Link>
            </Typography>
          </FlexBox>
        </div>
      </Grid>
    </Grid>
  );
};

export default Account;
