import React from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  useMediaQuery,
} from "@mui/material";
import { FlexBox } from "../../shared/components/FlexBox";
// import sellwith from "../../assets/images/svg/sellwith-group.svg";
// import buywith from "../../assets/images/svg/buywith-group.svg";
import sellwith from "../../assets/images/svg/sellwith-only-logo.svg";
import buywith from "../../assets/images/svg/buywith-only-logo.svg";

interface props {
  selectedId: number;
  setActiveId: (value: number) => void;
  setIsActive: () => void;
  setAppType: (value: string) => void;
  logOut: () => void;
}

const UserAccount: React.FC<props> = ({
  selectedId,
  setActiveId,
  setIsActive,
  setAppType,
  logOut,
}) => {
  const is1300 = useMediaQuery("(max-width:1300px)");
  const option = [
    {
      id: 1,
      name: "buyWith",
      image: buywith,
      header: "BuyWith",
      text: "Align with the right suppliers and meet your CSR and ESG goals faster by integrating social impact funds into your bids and RFPs.",
    },
    {
      id: 2,
      name: "sellWith",
      image: sellwith,
      header: "SellWith",
      text: "Enhance your proposals and show more value to prospects and customers by integrating social impact funds, accelerating your CSR and ESG goals.",
    },
  ];
  return (
    <>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "24px",
          color: "#57585A",
          lineHeight: "38.4px",
          paddingBottom: "60px",
        }}
      >
        Choose your path to impact
      </Typography>

      <FlexBox
        sx={{
          display: "flex",
          flexDiection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Box>
          {option.map((item) => (
            <Box
              sx={{
                backgroundColor: `${
                  item.id === selectedId ? "#F2FAFD" : "#FFFFFF"
                }`,
                paddingY: "15px",
                width: `${is1300 ? "600px" : "880px"}`,
                borderRadius: "15px",
                display: "flex",
                border: `${
                  item.id === selectedId
                    ? "1px solid #249ACB"
                    : "1px solid #BCBEBF"
                }`,
                justifyContent: "center",
                alignItems: "center",
                gap: "40px",
                marginBottom: "15px",
                paddingX: `${is1300 ? "15px" : ""}`,
                cursor: "pointer",
              }}
              onClick={() => {
                setActiveId(item.id);
                setAppType(item.name);
                setIsActive();
              }}
            >
              <img
                style={{ width: "142px", height: "142px" }}
                src={item.image}
                alt="sellwith logo"
              />
              <Box sx={{ width: "600px", height: "87px" }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    // color: "#57585A",
                    color: `${item.id === selectedId ? "#249ACB" : "57585A"}`,
                    marginBottom: "20px",
                  }}
                >
                  {item.header}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#57585A",
                    marginBottom: "20px",
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
              {/* <Box>
                <FormControl sx={{ alignItems: "" }}>
                  <FormLabel>
                    <FormControlLabel
                      value="start"
                      control={
                        <Radio
                          style={{ position: "absolute", top: "-70px" }}
                          checked={selectedId === item.id}
                          color="info"
                        />
                      }
                      label=""
                      labelPlacement="start"
                    />
                  </FormLabel>
                </FormControl>
              </Box> */}
            </Box>
          ))}
        </Box>
      </FlexBox>
      {/* <Button
        sx={{
          width: `${is1300 ? "230px" : "480px"}`,
          marginBottom: "20px",
          color: "#ffffff",
          backgroundColor: "#249ACB",
          height: "50px"
          
        }}
        variant="contained"
        size="large"
        disabled={selectedId === 0}
        onClick={() => {
          setIsActive();
        }}
      >
        Next
      </Button> */}
      <Typography
        onClick={() => {
          localStorage.clear();
          logOut();
        }}
        sx={{
          cursor: "pointer",
          color: "#249ACB",
          fontSize: "16px",
          fontWeight: "700",
        }}
      >
        Cancel
      </Typography>
    </>
  );
};

export default UserAccount;
